import ReactDom from 'react-dom'
import App from './App'
import './index.css'
import React from 'react'
import {Routes,Route,Router} from 'react-router-dom'

import App2 from './App'

const index = () => (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<App2 />} />
        </Routes>
      </Router>
    </>
  
  )

  export default index
ReactDom.render(<App/>, document.querySelector("#root"))