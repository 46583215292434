import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {BiMessageSquareDetail} from 'react-icons/bi'
import { Link } from "react-scroll";


const Nav = () => {
  return (
    <nav>
      <ul>
            <li>
              <Link activeClass="active" smooth spy to="Index">
              <AiOutlineHome/>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="about">
              <AiOutlineUser/>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="experience">
              <BiBook/>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="contact">
              <BiMessageSquareDetail/>
              </Link>
            </li>
          </ul>
    </nav>
  )
}

export default Nav