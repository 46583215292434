import React from "react";
import "./portfolio.css";


const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

      <article class="card four" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Rewilding Experience<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2019 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Corrientes.
            </p>
       
            <a
              href="https://rewildingexperience.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>

        <article class="card seven" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            La Posta de los Toldos<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2022 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Santa Cruz.
            </p>
       
            <a
              href="https://lapostadelostoldos.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>

        <article class="card eight" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Glamping Isla Leones<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2023 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Chubut Argentina.
            </p>
       
            <a
              href="https://www.glampingislaleones.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card nine" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Glamping Los Palmares<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2022 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Chaco.
            </p>
       
            <a
              href="https://glampinglospalmares.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card tenth" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Glamping El Bermejito<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2023 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Chaco.
            </p>
       
            <a
              href="https://glampingelbermejito.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card eleventh" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Rincon del Socorro<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2019 - Present</h3>
            <h3 class="seats">FullStack Dev</h3>
            <p class="txt">
            National parks of Corrientes.
            </p>
       
            <a
              href="https://rincondelsocorro.com.ar/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        
        
        <article class="card five" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Estancia Iberá<span class="flag jsProject"></span>
            </h2>
            <h3 class="date">2020 - Present</h3>
            <h3 class="seats">Rol: Front End Developer</h3>
            <p class="txt">
            National park of Corrientes.
            </p>
       
            <a
              href="https://www.estanciaibera.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card six" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
           Lopez Hnos<span class="flag phpProject"></span>
            </h2>
            <h3 class="date">2020 - PRESENT </h3>
            <h3 class="seats">Rol: Full Stack Devloper</h3>
            <p class="txt">
            
            </p>
           
            <a
              href="https://www.lopezhnos.com.ar/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card two" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            StuntArgentino1<span class="flag wordpressProject"></span>
            </h2>
            <h3 class="date">2023 </h3>
            <h3 class="seats">Rol: Wordpress Developer</h3>
            <p class="txt">
            Stunt Argentino 1 dedicated to selling stickers to participate in vehicle raffles.
            </p>
           
            <a
              href="https://stuntargentino1.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card twelveth" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            IFE<span class="flag wordpressProject"></span>
            </h2>
            <h3 class="date">2016 </h3>
            <h3 class="seats">Rol: Wordpress Developer</h3>
            <p class="txt">
            Business Development Institute.
            </p>
           
            <a
              href="https://ife.gob.ar/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card three" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
            Volta Bikes<span class="flag phpProject"></span>
            </h2>
            <h3 class="date">2020 - Present</h3>
            <h3 class="seats">Rol: FullStack Developer</h3>
            <p class="txt">
            Volta Bikes is an important company at the national level that provides quality and a very good experience to its customers with its products which are displayed on the web.
            </p>
           
            <a
              href="https://voltabikes.com.ar/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
        <article class="card one" >
          <div class="thumb"></div>
          <div class="infos">
            <h2 class="title">
              Pase Ticket<span class="flag reactProject"></span>
            </h2>
            <h3 class="date">november 2022</h3>
            <h3 class="seats">Rol: FullStack Developer</h3>
            <p class="txt">
            Pase Ticket is a national Argentine company with the purpose of providing the possibility of creating events and selling tickets.
            </p>
            
            <a
              href="https://paseticket.com/"
              className="details"
              rel="noopener noreferrer"
              target="_blank"
            >
              View Project
            </a>
          </div>
        </article>
      
       
      </div>
    </section>
  );
};

export default Portfolio;
