import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'



const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dcqw83m', 'template_n0c16pg', form.current, 'ryt-286fK5HNaApQ0')
    e.target.reset();
  };
  return (
    <section id='contact'> 
    <h5>Get in Touch</h5>
    <h2>Contact To me</h2>

    <div className="container contact__container">
      <div className="contact__options">
        <article className='contact__option'>
          <MdOutlineEmail className='contact__option-icon'/>
          <h4>Email</h4>
          <h5>flgwebdeveloper@outlook.es</h5>
          <a href="mailto:lgwebdeveloper@outlook.es">Send a Message</a>
        </article>
       
        <article className='contact__option'>
          <BsWhatsapp className='contact__option-icon'/>
          <h4>WhatsApp</h4>
          <a href="https://wa.link/mwtqgj" rel="noopener noreferrer" target="_blank">Send a Message</a>
        </article>
      </div>
      {/* End of Contact Options */}
      <form ref={form} onSubmit={ sendEmail }>
        <input type="text" placeholder='Your Full Name' required />
        <input type="email" placeholder='Yout Email'  requiered/>
        <textarea name="message" rows='7' placeholder='Your Message' requiered></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
    </div>
    </section>
  )
}

export default Contact