import React from 'react'


const Up = () => {
    
  return (
    <div>

    <a href="#Index" className='BotonUp'>UP</a>

    </div>
  )
}

export default Up