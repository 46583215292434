import React from "react";
import "./experience.css";


const Experience = () => {
  return (
    <section id="experience">
      <h5>The Skills I have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <div className="experience__content">
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-html5-plain-wordmark"></i>
                </h4>
              </div>
            </article>

            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-css3-plain-wordmark"></i>
                </h4>
              </div>
            </article>

            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  {" "}
                  <i class="devicon-react-original-wordmark"></i>
                </h4>
              </div>
            </article>

            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-php-plain"></i>
                </h4>
              </div>
            </article>

            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-bootstrap-plain-wordmark"></i>
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-sass-original"></i>
                </h4>
              </div>
            </article>

            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-javascript-plain"></i>
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  {" "}
                  <i class="devicon-git-plain"></i>{" "}
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-wordpress-plain"></i>
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-nodejs-plain"></i>
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-express-original"></i>
                </h4>
              </div>
            </article>
            <article className="experience__details">
              <div>
                <h4 className="iconI">
                  <i class="devicon-npm-original-wordmark"></i>
                </h4>
              </div>
            </article>
          </div>
        </div>

        {/* END FRONTED */}
      </div>
    </section>
  );
};

export default Experience;
