import React from "react";
import "./footer.css";
import {BsLinkedin} from 'react-icons/bs'
import {GoMarkGithub} from 'react-icons/go'
import {FaInstagramSquare} from 'react-icons/fa'


const Footer = () => {
  return (
    <footer>
      <a href="/" className="footer__logo">
        Franco L. Garay
      </a>
      <ul className="permalinks">
        <li>
          <a href="/">Home</a>
        </li>
        <li><a href="#about">About</a>
          </li>
        <li><a href="#experience">Experience</a>
          </li>
        <li><a href="#portfolio">Portfolio</a>
          </li>
          <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/franco-lautaro-garay-165489219/" rel="noopener noreferrer" target='_blank'><BsLinkedin/></a>
        <a href="https://github.com/FrancoGW" rel="noopener noreferrer" target='_blank'><GoMarkGithub/></a>
        <a href="https://www.instagram.com/lautafrancogaray/" rel="noopener noreferrer" target='_blank'><FaInstagramSquare/></a>
      </div>
      <div className="footer__copyright">
        <small>&copy;Franco L. Garay Full Stack Web Developer. All rights reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
