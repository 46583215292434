import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/img-portfolio (1).png'

const Header = () => {
  return (
    <header id="Index">
      <div  className="container header__container">
            <div className="contentAll">
            <div className="contentHeader">
                  <h5>Hello I'm</h5>
                  <h1>Franco Garay</h1>
                  <h5 className="text-light edit">Fullstack Web Developer</h5>
                  <CTA  />
               </div>
               <div className="me">
              <img src={ME} alt="" />
            </div>
            </div>
               
       

        

      </div>
    </header>
  )
}

export default Header