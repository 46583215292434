let timelineElements = [
  {
    id: 2,
    title: "Freelancer",
    location: "Argentina",
    description:
      "I have worked alone since the beginning of my career, successfully completing all the projects I faced. I also collaborated with important companies during this time.",
    buttonText: "View Backend Projects",
    date: "January 2019 - Present",
    icon: "work",
  },
  {
    id: 1,
    title: "Full Stack Web Developer",
    location: "Epimedia Estudio",
    description:
      "Build, migrate, update websites of important companys in Corrientes, Argentina. Here i used technologies like javascript, php, nodejs, mysql",
    buttonText: "View Frontend Projects",
    date: "January 2020 - December 2022",
    icon: "work",
  },
  {
    id: 3,
    title: "Front-End Developer in ReactJs",
    location: "CoderHouse",
    description: "Online carrer",
    buttonText: "Course Certificate",
    date: "",
    icon: "school",
  },
];

export default timelineElements;
