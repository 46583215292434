import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Up from './components/up/Up'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Line from './components/line/line.js'


const App = () => {
  return (
    <>
    
    
    <Header/>
    <Up/>
    <Nav/>
    <About/>
    <Line/>
    <Experience/>
    {/* <Services/> */}
    <Portfolio/>
    {/* <Testimonials/> */}
    <Contact/>
    <Footer/>
 
    </>

  )
}

export default App